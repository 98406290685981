<template>
  <div class="drive">
    <div class="driveCenter">
      <div class="video-container">
        <video
          controls
          :poster="require('../../assets/LOGO123.jpg')"
          muted
          autoPlay
        >
          <source
            :src="require('../../assets/drive-video.mp4')"
            type="video/mp4"
          />
          <!-- <source src="movie.ogg" type="video/ogg" /> -->
          您的浏览器不支持 HTML5 video 标签。
        </video>
      </div>
      <div class="text-container">
        <div class="title">ドライビングレッスン</div>
        <div class="main">
          <p>
            ドライビングレッスンスクール体験
            モータースポーツ体験及エンターテイメントプロジェクトとして、ドライビングスクールは海外でも人気が高まっています.娯楽、スリルを組み合わせた一種のスポーツアクティビティであり、車好きの方がに愛されています。
          </p>
          <p>
            より多くのお客様にこのスポーツアクティビティを楽しんで頂くために、当社は一般の方向けに対応する2種類のサーキットドライビングスクール体験ができるトレーニングコースを提供しています。
          </p>
          <!-- <br /> -->
          <p>
            ①ヴァーチャルコース：レーシングシミュレーターを使用したサーキットでの基本的なドライビング知識の講習。
          </p>
          <p>
            ②リアルコース：実際のサーキットを使ってリアルな走りをレッスンしながら走行体感。
          </p>
          <p>
            さまざまなお客様向けにパーソナライズされた専門的なドライビングレッスンコースを開発致します。
            お楽しみに。
          </p>
        </div>
      </div>

      <div style="display: flex" class="img-container">
        <img src="@/assets/drive1.jpg" alt="" style="margin-left: 0" />
        <img src="@/assets/drive2.jpg" alt="" />
        <img src="@/assets/drive3.jpg" alt="" style="margin-right: 0" />
      </div>
      <div style="display: flex" class="img-container">
        <img src="@/assets/drive4.jpg" alt="" style="margin-left: 0" />
        <img src="@/assets/drive5.jpg" alt="" />
        <img src="@/assets/drive6.jpg" alt="" style="margin-right: 0" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PcDrive'
}
</script>

<style lang="scss" scoped>
.drive {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  .driveCenter {
    width: 1200px;
    margin: 0 auto;
    .video-container {
      margin: 20px 0;
      width: 100%;
      height: 600px;
      video {
        height: 100%;
      }
    }
    .text-container {
      margin-bottom: 30px;
      .title {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 30px;
        letter-spacing: 2px;
      }

      .main {
        padding: 20px;
        font-size: 28px;
        text-indent: 2em;
        line-height: 40px;
        text-align: justify;
        border: 2px solid #fff;
        p {
          margin: 8px 0;
        }
      }
    }
    .img-container {
      margin: 30px 0;
      img {
        margin: auto;
        width: 32%;
        height: 220px;
        box-shadow: 2px 0 6px 8px rgb(0, 0, 0),
          6px 4px 6px 8px rgb(255, 255, 255, 0.7);
      }
    }
  }
}
</style>
