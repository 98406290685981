<template>
  <div>
    <div class="text-container">
      <div class="title">会社概要</div>
      <div class="main">
          <div class="item-container">
            <div class="item-title">本社 </div>
            <div class="text-container">
              <span>〒262-0032 千葉市花見川区幕張町5-417-9-1205</span>
              <span>TEL：043-375-3107</span>
              <span>FAX：043-375-3107</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">船橋店舗</div>
            <div class="text-container">
              <span>〒274-0065千葉県船橋市高根台6-2-25（MOTO VISION LAND）</span>
              <span>TEL：043-375-3107</span>
              <span>FAX：043-375-3107</span>
            </div>
          </div>
        </div>
    </div>
    <div class="text-container" style="margin-top:30px">
        <div class="title">海外の店舗一覧 </div>
        <div class="main">
          <div class="item-container">
            <div class="item-title">四輪店舗</div>
            <div class="text-container">
              <span>〒101125 北京市通州区刘庄新村底商3栋7号 MBK</span>
              <span>TEL：010-8729-8195</span>
              <span>FAX：010-8729-8195</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">二輪店舗</div>
            <div class="text-container">
              <span>〒100024 北京市朝阳区 双桥中路50号院E50艺术区F11-03  JAN Helmet  Display STORE</span>
              <span>TEL：+86 18500459058</span>
              <span>メアド：wei.huang.bmw@gmail.com</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">二輪店舗</div>
            <div class="text-container">
              <span>〒100043  北京市石景山区古城街道首钢园68号 TUMBLER STORE</span>
              <span>TEL：+86 13051736570</span>
              <span>メアド：wei.huang.bmw@gmail.com</span>
            </div>
          </div>
        </div>
        <div class="between-logo-container">
          <img src="@/assets/between-logo.png" />
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "ShieldView",
};
</script>
<style lang="scss" scoped>
.text-container {
  margin: 10px auto;
  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
  }
  .main {
    border: 1px solid #fff;
    border-top: none;
    .item-container {
      display: flex;
      font-size: 12px;
      align-items: center;
      border-top: 1px solid #fff;
      .item-title {
        padding: 10px 0;
        width: 80px;
        display: flex;
        justify-content: center;
      }
      .text-container {
        border-left: 1px solid #fff;
        padding: 10px 0 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        flex:1;
        span {
          text-align: left;
        }
      }
    }
  }
}
.between-logo-container {
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
    img {
      display: block;
      width: 100%;
      box-shadow: 1px 0 3px 4px rgb(0, 0, 0),
        3px 2px 3px 4px rgb(255, 255, 255, 0.7);
    }
  }
</style>
