<template>
  <div class="shield">
    <div class="associationCenter">
      <div class="text-container">
        <div class="title">本社 </div>
        <div class="main">
          <div class="item-container">
            <div class="item-title">本社 </div>
            <div class="text-container">
              <span>〒262-0032 千葉市花見川区幕張町5-417-9-1205</span>
              <span>TEL：043-375-3107</span>
              <span>FAX：043-375-3107</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">船橋店舗</div>
            <div class="text-container">
              <span>〒274-0065千葉県船橋市高根台6-2-25（MOTO VISION LAND）</span>
              <span>TEL：043-375-3107</span>
              <span>FAX：043-375-3107</span>
            </div>
          </div>
        </div>
      </div>

      <div class="text-container" style="margin-top:60px">
        <div class="title">海外の店舗一覧 </div>
        <div class="main">
          <div class="item-container">
            <div class="item-title">四輪店舗</div>
            <div class="text-container">
              <span>〒101125 北京市通州区刘庄新村底商3栋7号 MBK</span>
              <span>TEL：010-8729-8195</span>
              <span>FAX：010-8729-8195</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">二輪店舗</div>
            <div class="text-container">
              <span>〒100024 北京市朝阳区 双桥中路50号院E50艺术区F11-03  JAN Helmet  Display STORE</span>
              <span>TEL：+86 18500459058</span>
              <span>メアド：wei.huang.bmw@gmail.com</span>
            </div>
          </div>
          <div class="item-container">
            <div class="item-title">二輪店舗</div>
            <div class="text-container">
              <span>〒100043  北京市石景山区古城街道首钢园68号 TUMBLER STORE</span>
              <span>TEL：+86 13051736570</span>
              <span>メアド：wei.huang.bmw@gmail.com</span>
            </div>
          </div>
        </div>
        <div class="between-logo-container">
          <img src="@/assets/between-logo.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShieldView",
};
</script>

<style lang="scss" scoped>
.shield {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  .associationCenter {
    width: 1200px;
    margin: 0 auto;
    .text-container {
      margin: 30px auto;
      .title {
        text-align: left;
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 10px;
        letter-spacing: 5px;
        margin-bottom: 30px;
      }
      .main {
        border: 1px solid #fff;
        border-top: none;
        .item-container {
          display: flex;
          font-size: 26px;
          line-height: 40px;
          align-items: center;
          border-top: 1px solid #fff;
          letter-spacing: 2px;
          font-weight: lighter;
          .item-title {
            padding: 20px 0;
            width: 200px;
            display: flex;
            justify-content: center;
          }
          .text-container {
            border-left: 1px solid #fff;
            padding: 20px 0 20px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            flex: 1;
            span {
              text-align: left;
            }
          }
        }
      }
      .between-logo-container {
        margin-top: 30px;
        width: 100%;
        padding: 10px 0;
        img {
          display: block;
          width: 100%;
          box-shadow: 2px 0 6px 8px rgb(0, 0, 0), 6px 4px 6px 8px rgb(255, 255, 255, 0.7);
        }
      }
    }
  }
}
</style>
